import React from "react";
// import Head from "next/head";
import CustomDataProvider from "./CustomDataProvider";

import { ResourceGuesser } from "@api-platform/admin";
import { DomainCreate, DomainEdit, DomainList } from "./../Crud/Domains/Domain";
import { UserCreate, UserEdit, UserList, UserShow } from "./../Crud/Users/User";
import { AvatarCreate, AvatarEdit, AvatarList, AvatarShow } from "./../Crud/Avatars/Avatar";
import { ServiceCreate, ServiceEdit, ServiceList } from "./../Crud/Services/Service";
import { TagCreate, TagEdit, TagList } from "./../Crud/Tags/Tag";
import { AvatarDomainTagCreate, AvatarDomainTagEdit, AvatarDomainTagList } from "./../Crud/Tags/AvatarDomainTag";
import { AnnouncementCreate, AnnouncementEdit, AnnouncementList } from "./../Crud/Announcements/Announcement";
import { PublicationCreate, PublicationEdit, PublicationList } from "../Crud/Publications/Publication";
import { EventCreate, EventEdit, EventList } from "../Crud/Events/Event";
import { SouvenirCreate, SouvenirEdit, SouvenirList, SouvenirShow } from "./../Crud/Souvenirs/Souvenir";
import { WifiCreate, WifiEdit, WifiList } from "./../Crud/Wifi/Wifi";
import customTheme from "./../customTheme";
import customLayout from "./../customLayout";
import AuthProvider from "./AuthProvider";
import { HydraAdmin } from "@api-platform/admin";

let ENTRYPOINT = process.env.REACT_APP_API_URL;
// let ENTRYPOINT = "http://geotest.loc/api";

const AdminLoader = () => {
  if (typeof window !== "undefined") {
    return (
      <HydraAdmin
        dataProvider={CustomDataProvider}
        authProvider={AuthProvider}
        entrypoint={ENTRYPOINT}
        theme={customTheme}
        layout={customLayout}
      >
        {(permissions) => (
          <>
            <ResourceGuesser
              name="domains"
              edit={DomainEdit}
              create={permissions.includes("ROLE_ADMIN","ROLE_DOMAIN_ADMIN") ? DomainCreate : null}
              list={DomainList}
            />
            <ResourceGuesser
              name="services"
              edit={ServiceEdit}
              create={ServiceCreate}
              list={ServiceList}
            />
            <ResourceGuesser
              name="announcements"
              edit={AnnouncementEdit}
              create={AnnouncementCreate}
              list={AnnouncementList}
            />
            <ResourceGuesser
              name="publications"
              edit={PublicationEdit}
              create={PublicationCreate}
              list={PublicationList}
            />
            <ResourceGuesser
              name="events"
              edit={EventEdit}
              create={EventCreate}
              list={EventList}
            />
            <ResourceGuesser
              name="souvenirs"
              edit={SouvenirEdit}
              create={SouvenirCreate}
              list={SouvenirList}
              show={SouvenirShow}
            />
            {permissions.includes("ROLE_ADMIN") && (
              <>
                <ResourceGuesser
                  name="users"
                  edit={UserEdit}
                  create={UserCreate}
                  list={UserList}
                  show={UserShow}
                />
                <ResourceGuesser
                  name="avatars"
                  edit={AvatarEdit}
                  create={AvatarCreate}
                  list={AvatarList}
                  show={AvatarShow}
                />
              </>
            )}

            <ResourceGuesser
              name="tags"
              edit={TagEdit}
              create={TagCreate}
              list={TagList}
            />
            {/* <ResourceGuesser
              name="avatar_tags"
              edit={AvatarTagEdit}
              create={AvatarTagCreate}
              list={AvatarTagList}
            /> */}
            <ResourceGuesser
              name="avatar_domain_tags"
              edit={AvatarDomainTagEdit}
              create={AvatarDomainTagCreate}
              list={AvatarDomainTagList}
            />
            <ResourceGuesser
              name="wifis"
              edit={WifiEdit}
              create={WifiCreate}
              list={WifiList}
            />
          </>
        )}
      </HydraAdmin>
    );
  }

  return <></>;
};

const AdminLogin = () => (
  <>
    {/* <Head>
      <title>Iop Admin</title>
    </Head> */}
    <AdminLoader />
  </>
);
export default AdminLogin;
