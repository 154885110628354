import jwtDecode from "jwt-decode";

import CustomDataProvider from "./CustomDataProvider";

let ENTRYPOINT = process.env.REACT_APP_API_URL;
// let ENTRYPOINT = "http://geotest.loc/api";

export default {
  login: ({ username, password }) => {
    const request = new Request(`${ENTRYPOINT}/authentication_token`, {
      method: "POST",
      body: JSON.stringify({ phone: username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token }) => {
        localStorage.setItem("token", token);
      });
  },
  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  checkAuth: () => {
    try {
      if (
        !localStorage.getItem("token") ||
        new Date().getTime() / 1000 >
          // @ts-ignore
          jwtDecode(localStorage.getItem("token"))?.exp
      ) {
        return Promise.reject();
      }
      return Promise.resolve();
    } catch (e) {
      // override possible jwtDecode error
      return Promise.reject();
    }
  },
  checkError: (err) => {
    if ([401, 403].includes(err?.status || err?.response?.status)) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: async () => {
    try {
      const user = await CustomDataProvider.getUser();
      const roles = user.roles;
      console.log(roles);

      return Promise.resolve(roles);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};
